import Modal from "react-modal/lib/components/Modal";
import React, { useState } from 'react'
import './assets/scss/styles.scss'
import { FaTimesCircle } from "react-icons/fa";
import {translate} from '../../assets/js/functions'
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import VideoModalMultiVideo from '../Modals/VideoModalMultiVideo';

export default function VideoModalMulti(props) {
    const [current, setCurrent] = useState(0);
    const total = props.audiovisual.length;

    function _onHandleClose(){
        props.onClose && props.onClose(!props.modal);
    }

    function prev() {
        let index = current - 1;
        if (index < 0) {
            index = total - 1;
        }console.log(index);
        setCurrent(index);
    }
    function next() {
        let index = current + 1;
        if (index >= total) {
            index = 0;
        }
        console.log(index);
        setCurrent(index);
    }

    return (
        <Modal  isOpen={props.modal}
                onRequestClose={() => _onHandleClose()}
                ariaHideApp={false}
                contentLabel="videoModal">
                    {props.audiovisual ?
                    <>
                        <div className="modal-video">
                            {props.audiovisual.map((value, index) =>
                            <VideoModalMultiVideo value={value} index={index} current={current} />
                            )}


                            <span className="modal-close" onClick={() => _onHandleClose()}>
                                <FaTimesCircle/>
                            </span>

                        </div>
                        <p>
                            <button className="arrows" onClick={event => prev()}><FaAngleLeft /></button> {translate('Video')} {(current + 1)}/{total}<button className="arrows" onClick={event => next()}><FaAngleRight /></button>
                        </p>
                    </>
                    : <div className="loader1"><div></div><div></div></div>}
        </Modal>
    )
}
