import axios from "axios";
import React from "react";
import { domain } from "./api";
import { parse, format } from 'date-fns'

export default function JsonDump(props) {
        return <pre style={{"background-color": "#fff",  "border": "2px solid black"}}>{JSON.stringify(props.data, null, 4)}</pre>
}

export function getClassName(item){
    return item.props.className ? "-"+item.props.className : "";
}

export function language(){
    return window.location.search.split('id=')[1]
}

export function translate(text){
    const tranlates = require("../translates.json") ? require("../translates.json") : null;
    const lang      = getCurrentLanguage();

    if (text === '') {
        return '';
    }

    switch(lang){
        case "es":
            return tranlates[lang][text] || text;
        case "eu":
            return tranlates[lang][text] || text;
        case "en":
            return tranlates[lang][text] || text;
        case "fr":
            return tranlates[lang][text] || text;
        default:
            return text;
    }
}

export function getCurrentLocationByLanguage(lang){
    return "/"+window.location.pathname;
}

export function getCurrentLanguage(){
    const lang = window.location.pathname.split('/')[1];
    let validLang = false;
    getLanguages().map(
        res => res === lang ? validLang = true : false
    );
    if(validLang) return lang;
    return getLanguages()[0];
}

export function getLanguages(){
    return ['es', 'eu', 'en', 'fr'];
}

export function getApiCurrentLanguage(){
    switch(getCurrentLanguage()){
        case 'es': return 'spa';
        case 'eu': return 'eus';
        case 'en': return 'eng';
        case 'fr': return 'fra';
        default: return 'spa';
    }
}

export function isSafari(){
    if (navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1) {return true}
    return false;
}

export function languageRoute(lang, route = ''){
    if(lang) return window.location.host+"/"+lang//window.location.host+'/'+lang+route.replace('/'+getCurrentLanguage(), '');
    return window.location.host+route;
}

export function getPath(){
    const location = window.location;
    let path = location.pathname+location.search+location.hash;
    return path.replace(languageRoute(''), '');
}

export function isValidLanguage(){
    const lang = window.location.pathname.split('/')[1];
    let validLang = false;
    getLanguages().map(
        res => res === lang ? validLang = true : null
    );
    return validLang;
}

export function getUrlByLanguage(url){
    if(url.split('/')[1] === getCurrentLanguage()){
        return url;
    }
    return "/"+getCurrentLanguage()+url;
}

export function structureData(data){
    if(!data) return;

    let aData = [];
    data.map(
        val =>
        aData.push(
            {
                title: val.title,
                media: getMedia(val.indexations),
                id: val.term_id
            }
        )
    );
}

function getMedia(data){
    JSON.parse(data).map(
        val =>
        axios.post(domain+'/dedalo/lib/dedalo/publication/server_api/v1/json/fragment_from_index_locator?code=inm3014rtAw&db_name=dedalo4_public&lang=lg-spa&index_locator='+JSON.stringify(val)).then(res => {return res})
    )
}

export function getTypeByTermId(item){
    const type = item.split("_")[0]
    switch(type){
        case "peri1":
            return "period"
        case "on1":
            return "onomastic"
        case "ts1":
            return "thematic"
        default:
            return null
    }
}

export function cleanArrayUndefined(items){
    return items.filter(function(x) {
        return x !== undefined;
   });
}


export var groupBy = function(xs, key) {
    return xs.reduce(function(rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  }

export function cleanArray(items){
    return [...new Set(items.map(e => JSON.stringify(e)))].map(e => JSON.parse(e))
}

export function getPosterframe(video_url){
    let posterframe_url = video_url
	posterframe_url = posterframe_url.replace(/\/404\//g, '/posterframe/')
	posterframe_url = posterframe_url.replace(/\.mp4/g, '.jpg')

    const ar_parts = posterframe_url.split("?")
        if (typeof ar_parts[0]!=="undefined") {
            posterframe_url = ar_parts[0]
        }

    return posterframe_url;
}

export function normalizeText(text, minus = false){
    if (minus) return text.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g,"")
    return text.normalize('NFD').replace(/[\u0300-\u036f]/g,"")
}

export const colorShade = (col, amt) => {
    switch(col){
        case 'lugares':
        case 'tokiak':
        case 'sites':
        case 'lieux':
            col = '#a3233a';
        break;
        case 'nombres':
        case 'izenak':
        case 'names':
        case 'noms':
            col = '#bc622e';
        break;
        case 'represion':
        case 'errepresioa':
        case 'repression':
        case 'repression':
            col = '#5ca035';
        break;
        case 'memoria':
        case 'memory':
        case 'memoire':
            col = '#3595a0';
        break;
        case 'sociedad':
        case 'gizartea':
        case 'society':
        case 'societe':
            col = '#6735a0';
        break;
        default: col = '#000';
    }

    col = col.replace(/^#/, '')
    if (col.length === 3) col = col[0] + col[0] + col[1] + col[1] + col[2] + col[2]

    let [r, g, b] = col.match(/.{2}/g);
    ([r, g, b] = [parseInt(r, 16) + amt, parseInt(g, 16) + amt, parseInt(b, 16) + amt])

    r = Math.max(Math.min(255, r), 0).toString(16)
    g = Math.max(Math.min(255, g), 0).toString(16)
    b = Math.max(Math.min(255, b), 0).toString(16)

    const rr = (r.length < 2 ? '0' : '') + r
    const gg = (g.length < 2 ? '0' : '') + g
    const bb = (b.length < 2 ? '0' : '') + b

    return `#${rr}${gg}${bb}`
  }

  export function extractNumber(string) {
    let numArray = string.split('').map(item => {
      if (typeof +item === 'number' && !isNaN(+item)) return +item
    })
    return +numArray.join('')
  }

  export function needsAlphabet(id){
    return ["on1_1879", "on1_2104"].includes(id)
  }

  export function victimNameDisplay(name, surname) {
    if (name !== null && name !== '' && surname !== null && surname !== '') {
        return surname+', '+name;
    }
    if (surname !== null && surname !== '') {
        return surname;
    }
    if (name !== null && name !== '') {
        return name;
    }
    return '';
  }

  export function VictimNameAndLocationDisplay(victim) {
    const name = victimNameDisplay(victim.name, victim.surname);
    if (victim.location) {
        return name + ' | <span>' + locationsName(victim.location) + '</span>';
    }
    return name;
  }

  export function formatDate(date) {
    var string = date.split(" ");
    if (string[0].match(/00-00$/)) {
        string = string[0].split('-');
        return (string[0]);
    }
    return format(parse(string[0], 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy');
  }

  export function customTrim(string) {
    return string.replace(/ \| $/i, '');
  }

  export function locationsName(string) {
    if (string == null) {
        return '';
    }
    var parts = string.split(",");
    if (parts.lenght === 0) {
        return '';
    }
    return parts[0];
  }


  export function localeContains(base, sub) {
    if(sub === "") return true;
    if(!sub || !base.length) return false;
    sub = ""+sub;
    if(sub.length>base.length) return false;
    let ascii = s => s.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
    return ascii(base).includes(ascii(sub));
}

export function leafletIcon(color = '#a3233a') {
    let L = window.L;

    const markerHtmlStyles = `
        background-color: ${color};
        width: 1.4rem;
        height: 1.4rem;
        display: block;
        left: -.7rem;
        top: -.7rem;
        position: relative;
        border-radius: 2rem 2rem 0;
        transform: rotate(45deg);
        border: 1px solid #FFFFFF`

    return L.divIcon({
        className: "my-custom-pin",
        iconAnchor: [0, 12],
        labelAnchor: [-6, 0],
        popupAnchor: [0, -36],
        html: `<span style="${markerHtmlStyles}" />`
    });
}

export function leafletNumberIcon(number, color = '#a3233a') {
    let L = window.L;

    const markerHtmlStyles = `
        background-color: ${color};
        width: 28px;
        height: 28px;
        display: flex;
        left: -14px;
        top: -14px;
        position: relative;
        border-radius: 50%;
        text-align: center;
        align-items: center;
        justify-content: center;
        color: #FFFFFF;
        font-size: 16px;
        border: 1px solid #FFFFFF`

    return L.divIcon({
        className: "my-custom-pin",
        iconAnchor: [0, 13],
        //labelAnchor: [-6, 0],
        //popupAnchor: [0, -36],
        html: `<span style="${markerHtmlStyles}">${number}</span>`
    });
}

export function Year(date) {
    return date.split('-')[0];
}

export function referenciaBiblio(value) {
    const ref = value.publications_data[0];
    let refTest = [];
    if (ref.authors) {
        refTest.push('<strong>'+ref.authors+'</strong>');
    }
    if (ref.title) {
        refTest.push('<em>'+ref.title+'</em>');
    }

    let refExtra = [];
    if (ref.editorial) {
        refExtra.push(ref.editorial);
    }
    if (ref.publication_date) {
        refExtra.push(Year(ref.publication_date));
    }
    if (value.pages) {
        refExtra.push(value.pages);
    }
    //value.pages

    //Apellido, Nombre del autor/a. Título del libro en cursiva. Lugar de publicación: editorial, año de publicación, número de página.
    refTest.push(refExtra.join(', '));
    return refTest.join('. ');
}