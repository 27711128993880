import React, { useEffect, useState } from 'react'
import 'reactjs-popup/dist/index.css';
import Modal from "react-responsive-modal";
import './assets/scss/styles.scss';
import { translate, formatDate } from '../../assets/js/functions';
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';

export default function VictimPrision(props) {

    const index = props.index;
    const data = props.data;
    const hasRefs = props.hasRefs;
    const sitesConvert = props.sitesConvert;

    const [open, setOpen] = useState(false);
    const closeModal = () => setOpen(false);

    function convertToSite(id) {
      if (sitesConvert.prision.includes(id)) {
        return 'prision';
      }
      if (sitesConvert.campo.includes(id)) {
        return 'campo';
      }
      if (sitesConvert.batallon.includes(id)) {
        return 'batallon';
      }
      return null;
    }

    function showOrigen(elem) {
      if (elem.origin_center) {
        const id = JSON.parse(elem.origin_center_data)[0];
        const site = convertToSite(id);
        if (site) {
          return <Link to={'/search/adv/sites/'+site+'/'+id}>{elem.origin_center}</Link>;
        } else {
          return elem.origin_center;
        }
      }
      return translate('Sin datos');
    }

    function showPrision(elem) {
      if (elem.detention_center) {
          const id = JSON.parse(elem.detention_center_data)[0];
          const site = convertToSite(id);
          if (site) {
            return <Link to={'/search/adv/sites/'+site+'/'+id}>{elem.detention_center}</Link>;
          } else {
            return elem.detention_center;
          }
      }
      return translate('Sin datos');
    }

    function showDestination(elem) {
      if (elem.destination_center) {
        const id = JSON.parse(elem.destination_center_data)[0];
        const site = convertToSite(id);
        if (site) {
          return <Link to={'/search/adv/sites/'+site+'/'+id}>{elem.destination_center}</Link>;
        } else {
          return elem.destination_center;
        }
      }
      return translate('Sin datos');
    }

    const closeIcon = (
      <svg width="57" height="57" xmlns="http://www.w3.org/2000/svg" focusable="false"><path d="M43.747 41.287 30.834 28.375l12.913-12.912-2.46-2.46-12.912 12.913-12.912-12.913-2.46 2.46 12.913 12.912-12.913 12.912 2.46 2.46 12.912-12.913 12.912 12.913z" fill="#021e24" fillRule="nonzero"></path></svg>
    );

    return dataReturn();

    function dataReturn(){
        return <>
        <tr>
          <td>
            {data.date_start === null && data.date_end === null?
            translate('Sin fecha')
            :
            <>
              {data.date_start?formatDate(data.date_start):''}<br/>{data.date_end?formatDate(data.date_end):''}
            </>
            }
          </td>
          <td><span className="loc">{showOrigen(data)}</span></td>
          <td><span className="loc">{showPrision(data)}</span></td>
          <td><span className="loc">{showDestination(data)}</span></td>
          {hasRefs?
          <td><span className="loc">
              {data.captive_signature?
              <a href="" onClick={(event) => {event.preventDefault();setOpen(o => !o)}}>{translate('Referencia/s')}</a>
          :
          translate('Sin referencia')
          }
          </span>
          </td>
          :''}
      </tr>
      {hasRefs?
        <Modal open={open}
            onClose={closeModal}
            closeIcon={closeIcon}>
          <div className="modal-popup">
            <div className="header">
            {translate('Referencia/s')}
            </div>
            <div className="content">
              {parse(data.captive_signature)}
            </div>
          </div>
        </Modal>
        :''}
        </>;
    }

}