import React from 'react';
/*import parse, { attributesToProps } from 'html-react-parser';*/
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { getCurrentLanguage } from '../../assets/js/functions';
import './assets/scss/styles.scss';


export default function LearnMore(){

/*
    function parsedText(html){
        const options = {
            replace: domNode => {
              if (domNode.attribs && domNode.name === 'mark') {
                // const props = attributesToProps(domNode.attribs);
                return <Link target={"_blank"} className="mark" to={"#"}><mark>{ domNode.children[0].data }</mark></Link>
              }
            }
          };
        return parse(html, options);
    }
*/

    return (
        <div id="learn-more">
            <Container>
                {
                    getCurrentLanguage() === 'es' ?
                        <span className='text'>
<span><strong>Oroibidea</strong>, camino de memoria, sugiere que hay múltiples sendas que transitar en la recuperación de la memoria en Navarra. Caminos diversos, como la memoria oral conservada por las familias; los documentos públicos y privados que hablan tanto de las víctimas como de los movimientos de recuperación de su memoria; imágenes fotográficas, sobre todo del período de las exhumaciones tempranas; materiales de investigación y bases de datos elaboradas durante años, como la del Fondo Documental de la Memoria Histórica de Navarra.</span><br/><br/>
<span>Todos esos materiales se entrelazan en Oroibidea en torno a un núcleo fundamental: las víctimas de las diferentes modalidades de violencia y represión, no solo la física. Esas víctimas pueden ser localizadas a través del <strong>buscador</strong> de la página principal, pero también utilizando el <strong>Filtro de víctimas</strong>, que permite refinar esa búsqueda por varios parámetros.</span><br/><br/>
<span>En la ficha de cada persona puede accederse a la información disponible sobre ella: los datos personales principales, la modalidad o modalidades represivas sufridas, y los documentos, imágenes, fotografías, fragmentos de entrevistas y ficha del FDMHN referidas a esa persona.</span><br/><br/>
<span>También es posible acceder en su versión completa a los <strong>fondos documentales</strong> y a las colecciones de entrevistas utilizadas en el proyecto. Pulsando en el botón Fondos documentales es posible acceder a todos los fondos procedentes de archivos públicos y privados que componen el archivo digital del Instituto Navarro de la Memoria. En él pueden encontrarse documentos, fotografías, imágenes y audiovisuales que han sido utilizados en el proyecto.</span><br/><br/>
<span>En el botón <strong>Entrevistas</strong> se accede al archivo de la memoria oral sobre el pasado traumático de Navarra. Ahí se encuentran las 161 entrevistas, procedentes de 14 <Link target={"_blank"} to={'/interviews/projects'} className='mark'>proyectos</Link> de investigación depositados en el Centro de Documentación del Instituto Navarro de la Memoria, que han permitido indizar 1.200 fragmentos. Todas las entrevistas son accesibles íntegramente, aunque se han creado fragmentos con contenidos destacables que son recuperables mediante la navegación por las palabras clave que los describen en el apartado <Link target={"_blank"} to={'/interviews/explore'} className='mark'>explora</Link>. Las 108 horas de entrevistas han sido transcritas y pueden buscarse todas las palabras dichas por los testimonios en el cajón central de búsqueda, que también sugiere las palabras clave con las que se han indizado los fragmentos de entrevistas.</span><br/><br/>
<span>El botón <strong>Documentos por población</strong> permite acceder a la documentación referida a la población de Navarra que deseemos buscar, pudiendo filtrar los contenidos por tipo de fondo o de documento.</span><br/><br/>
<span>En <strong>Mapas</strong> podemos acceder a desarrollos cartográficos que muestran información concreta sobre víctimas. Dentro de cada población o cada término existen referencias individuales a las víctimas que nos permiten acceder a sus respectivas fichas.</span><br/><br/>
<span>Por último, <strong>Exposiciones</strong> permite acceder a exposiciones virtuales que desarrollan temáticas específicas utilizando los fondos aquí contenidos, como la cautividad o las exhumaciones tempranas.</span><br/><br/>
<span>El archivo es bilingüe castellano-euskera. Las entrevistas se ofrecen en el idioma original, aunque en la navegación en castellano, las entrevistas en euskera están subtituladas en la lengua de navegación.</span><br/><br/>
<span>Para más información sobre el proyecto, pueden ponerse en contacto a través del correo electrónico: <a href="mailto:inm@navarra.es">inm@navarra.es</a></span>
                        </span>
                    :
                        <span className='text'>

<span><strong>Oroibideak</strong> Nafarroan oroimena berreskuratzeko bide asko daudela iradokitzen du. Hainbat bide, hala nola familiek kontserbatutako ahozko memoria; biktimei buruz zein haien memoria berreskuratzeko mugimenduei buruz hitz egiten duten dokumentu publiko eta pribatuak; argazki-irudiak, batez ere lurpetik goiz atera zireneko aldikoak; urteetan landutako ikerketa-materialak eta datu-baseak, hala nola Nafarroako Oroimen Historikoaren Dokumentazio Funtsarena.</span><br/><br/>
<span>Material horiek guztiak funtsezko nukleo baten inguruan elkartzen dira Oroibidean: indarkeria eta errepresio mota desberdinen biktimak, ez bakarrik fisikoa. Biktima horiek orri nagusiko <strong>bilatzailearen</strong> bidez aurki daitezke, baina baita <strong>Biktimen iragazkia</strong> erabiliz ere, bilaketa hori hainbat parametroren bidez fintzeko aukera ematen baitu.</span><br/><br/>
<span>Pertsona bakoitzaren fitxan, hari buruz eskuragarri dagoen informazioa eskura daiteke: datu pertsonal nagusiak, jasandako errepresio-modalitatea edo -modalitateak, eta pertsona horri buruzko dokumentuak, irudiak, argazkiak, elkarrizketa-zatiak eta FDMHNren fitxa.</span><br/><br/>
<span>Halaber, bertsio osoan ikus daitezke proiektuan erabilitako dokumentu-funtsak eta elkarrizketa-bildumak. <strong>"Dokumentu-funtsak"</strong> botoia sakatuz gero, Nafarroako Memoriaren Institutuko artxibo digitala osatzen duten artxibo publiko eta pribatuetako funts guztiak eskura daitezke. Bertan, proiektuan erabili diren dokumentuak, argazkiak, irudiak eta ikus-entzunezkoak aurki daitezke.</span><br/><br/>
<span><strong>Elkarrizketak</strong> botoian, Nafarroako iragan traumatikoari buruzko ahozko memoriaren artxibora sartzen da. Hor daude 161 elkarrizketa, Nafarroako Memoriaren Institutuaren Dokumentazio Zentroan gordetako 14 <Link target={"_blank"} to={'/interviews/projects'} className='mark'>ikerketa-proiektutatik</Link> ateratakoak, 1.200 zati adierazteko aukera eman dutenak. Elkarrizketa guztiak osorik eskura daitezke, nahiz eta eduki azpimarragarriak dituzten zatiak sortu diren, arakatze-atalean deskribatzen dituzten gako-hitzen nabigazioaren bidez berreskura daitezkeenak. Elkarrizketen 108 orduak transkribatuak izan dira, eta bilaketaren erdiko tiraderan testigantzek esandako hitz guztiak bila daitezke. Kaxoi horrek elkarrizketa-zatiak indexatzeko erabili diren gako-hitzak ere iradokitzen ditu.</span><br/><br/>
<span><strong>Dokumentuak herriaren arabera</strong> botoiaren bidez, bilatu nahi dugun Nafarroako biztanleriari buruzko dokumentazioa eskura daiteke, eta edukiak funts edo dokumentu motaren arabera iragaz daitezke.</span><br/><br/>
<span><strong>Mapetan</strong> biktimei buruzko informazio zehatza erakusten duten garapen kartografikoak ikus ditzakegu. Biztanleria edo termino bakoitzaren barruan, biktimei buruzko banakako erreferentziak daude, eta, horiei esker, dagozkien fitxak eskura ditzakegu.</span><br/><br/>
<span>Azkenik, <strong>Erakusketek</strong> gai espezifikoak garatzen dituzten erakusketa birtualetara sartzeko aukera ematen dute, hemen dauden funtsak erabiliz, hala nola gatibutasuna edo hobitik goiz ateratzea.</span><br/><br/>
<span>Artxiboa elebiduna da gaztelania-euskara. Elkarrizketak jatorrizko hizkuntzan eskaintzen dira, baina gaztelaniazko nabigazioan, euskarazko elkarrizketak nabigazio-hizkuntzan azpititulatuta daude.</span><br/><br/>
<span>Proiektuari buruzko informazio gehiago nahi izanez gero, jarri harremanetan posta elektronikoaren bidez: <a href="mailto:inm@navarra.es">inm@navarra.es</a></span>
                        </span>
                    }
            </Container>
        </div>
    )

}
