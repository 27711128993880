import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { getVictim, getSearchVictim, getTermIdByGroup } from '../../assets/js/api';
import { translate, victimNameDisplay, customTrim, locationsName, formatDate } from '../../assets/js/functions';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
//import parse from 'html-react-parser';
import VictimSearchCard from '../../components/Victim/VictimSearchCard';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom'
import './assets/scss/styles.scss';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import VictimDocs from '../../components/Victim/VictimDocs';
//import VictimImgs from '../../components/Victim/VictimImgs';
//import VictimPubs from '../../components/Victim/VictimPubs';
import VictimAudios from '../../components/Victim/VictimAudios';
import VictimPhotos from '../../components/Victim/VictimPhotos';
import VictimInterviews from '../../components/Victim/VictimInterviews';
import VictimBiblioRef from '../../components/Victim/VictimBiblioRef';

import VictimPrision from '../../components/Victim/VictimPrision';

import VictimPrisionMap from '../../components/Victim/VictimPrisionMap';
import VictimRecorridoJudicial from '../../components/Victim/VictimRecorridoJudicial';


export default function Victim(props){

    const [victimId, setVictimId] = useState(null);
    const [victimData, setVictimData] = useState(null);
    const [upnaLink, setUpnaLink] = useState(null);
    const [victimTab, setVictimTab] = useState(null);
    const [prisionMap, setPrisionMap] = useState(false);
    const [victimBiblioRef, setVictimBiblioRef] = useState(null);
    const [sitesConvert, setSitesConvert] = useState(null);

    const [victimTabsLink, setVictimTabsLink] = useState(null);
    const [victimTabsContent, setVictimTabsContent] = useState(null);

    const [prisionMovements, setPrisionMovements] = useState(null);

    useEffect(() => {
        if (props.match){
            const victimId = props.match.params.id;
            const victimDB = props.match.params.group;

            if(victimId){
                setVictimId(victimId);
                getSearchVictim(victimId).then(res => {
                    var victimInfo = res.data.result[0] ? res.data.result[0] : null;
                    setVictimData(victimInfo);

                    Promise.all([
                        getVictim(victimId, 'golpe').then(res => {
                            return res.data.result[0] ? res.data.result[0] : null;
                        }),
                        getVictim(victimId, 'economica').then(res => {
                            return res.data.result[0] ? res.data.result[0] : null;
                        }),
                        getVictim(victimId, 'exilio').then(res => {
                            return res.data.result[0] ? res.data.result[0] : null;
                        }),
                        getVictim(victimId, 'deportacion').then(res => {
                            return res.data.result[0] ? res.data.result[0] : null;
                        }),
                        getVictim(victimId, 'prision').then(res => {
                            return res.data.result[0] ? res.data.result[0] : null;
                        }),
                        getTermIdByGroup('prision'),
                        getTermIdByGroup('campo'),
                        getTermIdByGroup('batallon')
                    ]).then(values => {

                        var tab = 0;
                        var victimTabsLink = [];
                        var victimTabsContent = [];
                        var victimTab = 0;

                        const sitesConvert = {
                            'prision': values[5],
                            'campo': values[6],
                            'batallon': values[7]
                        };
                        setSitesConvert(sitesConvert);


                        if (values[0]) {
                            victimTabsLink.push(
                                <Tab key={'golpe'}><span>{translate('Víctima mortal')}</span></Tab>
                            );

                            victimTabsContent.push(
                                <TabPanel key={'golpe'}>
                                    <VictimSearchCard victimid={'golpe'} data={values[0]} victimdata={victimInfo} />
                                    <VictimRecorridoJudicial data={values[0].court_records_data} />
                                </TabPanel>
                            );
                            if (victimDB == 'golpe') {
                                victimTab = tab;
                            }
                            tab ++;
                            if (values[0].information_sources_uri) {
                                setUpnaLink(values[0].information_sources_uri);
                            }
                            if (values[0].bibliographic_references_data && values[0].bibliographic_references_data.length > 0) {
                                setVictimBiblioRef(values[0].bibliographic_references_data);
                            }
                        }
                        if (values[1]) {
                            victimTabsLink.push(
                                <Tab key={'economica'}><span>{translate('Represión económica')}</span></Tab>
                            );
                            victimTabsContent.push(
                                <TabPanel key={'economica'}>
                                    <VictimSearchCard victimid={'economica'} data={values[1]} victimdata={victimInfo} />
                                </TabPanel>
                            );
                            if (victimDB == 'economica') {
                                victimTab = tab;
                            }
                            tab ++;
                        }
                        if (values[2]) {
                            victimTabsLink.push(
                                <Tab key={'exilio'}><span>{translate('Exilio')}</span></Tab>
                            );
                            victimTabsContent.push(
                                <TabPanel key={'exilio'}>
                                    <VictimSearchCard victimid={'exilio'} data={values[2]} victimdata={victimInfo} />
                                </TabPanel>
                            );
                            if (victimDB == 'exilio') {
                                victimTab = tab;
                            }
                            tab ++;
                        }
                        if (values[3]) {
                            victimTabsLink.push(
                                <Tab key={'deportacion'}><span>{translate('Deportación')}</span></Tab>
                            );
                            victimTabsContent.push(
                                <TabPanel key={'deportacion'}>
                                    <VictimSearchCard victimid={'deportacion'} data={values[3]} victimdata={victimInfo} />
                                </TabPanel>
                            );
                            if (victimDB == 'deportacion') {
                                victimTab = tab;
                            }
                            tab ++;
                        }

                        if (values[4]) {
                            if (values[4].bibliographic_references_data && values[4].bibliographic_references_data.length > 0) {
                                setVictimBiblioRef(values[4].bibliographic_references_data);
                            }

                            if (values[4].information_sources_uri) {
                                setUpnaLink(values[4].information_sources_uri);
                            }

                            victimTabsLink.push(
                                <Tab key={'prision'}><span>{translate('Preso/a')}</span></Tab>
                            );
                            victimTabsContent.push(
                                <TabPanel key={'prision'}>
                                    {(values[4].movements_capture_data.length > 0 && sitesConvert !== null)?
                                    <>
                                    <h3>{translate('Lugares de encierro')}</h3>

                                            <table className="trecorrido">
                                                <thead>
                                                    <tr>
                                                        <th><svg width="25px" height="25px" viewBox="0 0 25 25" version="1.1">
                                                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                    <g transform="translate(-444, -785)">
                                                                        <g transform="translate(444, 785)">
                                                                            <polygon points="0 0 25 0 25 25 0 25"></polygon>
                                                                            <path d="M4.16666667,7.29166667 C4.16666667,6.14107344 5.09940677,5.20833333 6.25,5.20833333 L18.75,5.20833333 C19.9005932,5.20833333 20.8333333,6.14107344 20.8333333,7.29166667 L20.8333333,19.7916667 C20.8333333,20.9422599 19.9005932,21.875 18.75,21.875 L6.25,21.875 C5.09940677,21.875 4.16666667,20.9422599 4.16666667,19.7916667 L4.16666667,7.29166667 Z" id="Path" stroke="#000000" stroke-linecap="round" stroke-linejoin="round"></path>
                                                                            <line x1="16.6666667" y1="3.125" x2="16.6666667" y2="7.29166667" stroke="#000000" stroke-linecap="round" stroke-linejoin="round"></line>
                                                                            <line x1="8.33333333" y1="3.125" x2="8.33333333" y2="7.29166667" stroke="#000000" stroke-linecap="round" stroke-linejoin="round"></line>
                                                                            <line x1="4.16666667" y1="11.4583333" x2="20.8333333" y2="11.4583333" stroke="#000000" stroke-linecap="round" stroke-linejoin="round"></line>
                                                                            <line x1="11.4583333" y1="15.625" x2="12.5" y2="15.625"  stroke="#000000" stroke-linecap="round" stroke-linejoin="round"></line>
                                                                            <line x1="12.5" y1="15.625" x2="12.5" y2="18.75"  stroke="#000000" stroke-linecap="round" stroke-linejoin="round"></line>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </svg>
                                                            </th>
                                                        <th>
                                                            <div className="loc-head">{translate('Procedencia')}</div>
                                                        </th>
                                                        <th>
                                                            <div className="loc-head">{translate('Reclusion')}</div>
                                                        </th>
                                                        <th>
                                                            <div className="loc-head">{translate('Destino')}</div>
                                                        </th>
                                                        <th><svg width="25px" height="25px" viewBox="0 0 25 25" version="1.1">
                                                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                    <g transform="translate(-810, -783)">
                                                                        <g transform="translate(810, 783)">
                                                                            <polygon points="0 0 25 0 25 25 0 25"></polygon>
                                                                            <path d="M14.5833333,3.125 L14.5833333,7.29166667 C14.5833333,7.86696328 15.0497034,8.33333333 15.625,8.33333333 L19.7916667,8.33333333" stroke="#000000" stroke-linecap="round" stroke-linejoin="round"></path>
                                                                            <path d="M17.7083333,21.875 L7.29166667,21.875 C6.14107344,21.875 5.20833333,20.9422599 5.20833333,19.7916667 L5.20833333,5.20833333 C5.20833333,4.0577401 6.14107344,3.125 7.29166667,3.125 L14.5833333,3.125 L19.7916667,8.33333333 L19.7916667,19.7916667 C19.7916667,20.9422599 18.8589266,21.875 17.7083333,21.875 Z" stroke="#000000" stroke-linecap="round" stroke-linejoin="round"></path>
                                                                            <line x1="9.375" y1="9.375" x2="10.4166667" y2="9.375" stroke="#000000" stroke-linecap="round" stroke-linejoin="round"></line>
                                                                            <line x1="9.375" y1="13.5416667" x2="15.625" y2="13.5416667" stroke="#000000" stroke-linecap="round" stroke-linejoin="round"></line>
                                                                            <line x1="9.375" y1="17.7083333" x2="15.625" y2="17.7083333" stroke="#000000" stroke-linecap="round" stroke-linejoin="round"></line>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </svg>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {values[4].movements_capture_data.map((res, index) =>
                                                    <VictimPrision index={index} data={res} sitesConvert={sitesConvert} hasRefs={true} />
                                                )}
                                                </tbody>
                                            </table>
                                                <Link className="btn-map-square" to={'/search/victim/'+victimId+'/prisionmap'} onClick={() => setPrisionMap(true)}>{translate('Ver recorrido en mapa')}</Link>
                                    </>
                                    :''}
                                    <VictimRecorridoJudicial data={values[4].court_records_data} />
                                </TabPanel>
                            );
                            if (victimDB == 'prision') {
                                victimTab = tab;
                            }
                            if (values[4].movements_capture_data.length > 0 && victimDB === 'prisionmap') {
                                setPrisionMap(true);
                            }
                            if (values[4].movements_capture_data.length > 0) {
                                setPrisionMovements(values[4].movements_capture_data);
                            }
                            tab ++;
                        }

                        setVictimTab(victimTab);
                        setVictimTabsContent(victimTabsContent);
                        setVictimTabsLink(victimTabsLink);
                    });

                });
            }
        }
    }, []);

    function extras(victim) {

        if (victim.section_id === null) {
            return '';
        }
        return <>
        <h4>{translate('Referencias')}</h4>
        <ul className="victim-ref">
            {/*
                <VictimImgs victimid={elem} />
            */}
            {/*
            <VictimPubs victimid={victim.section_id} />
            */}
            <VictimDocs victimid={victim.section_id} />
            <VictimAudios victimid={victim.section_id} />
            <VictimPhotos victimid={victim.section_id} />
            <VictimInterviews victimid={victim.section_id} />
            <VictimBiblioRef refs={victimBiblioRef} />
            {(upnaLink && upnaLink.length > 0)?
            <li>
                <a href={JSON.parse(upnaLink)[0].iri} target='_blank'>
                <svg width="30" height="30"><defs><path id="a" d="M0 0h30v30H0z"/></defs><g fill="none" fillRule="evenodd"><mask id="b" fill="#fff"><use xlinkHref="#a"/></mask><g mask="url(#b)"><path d="M-4-4h38v38H-4z"/><path stroke="#A3233B" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" d="M11.833 18.167a5.542 5.542 0 0 0 7.917 0l6.333-6.334a5.598 5.598 0 0 0-7.916-7.916l-.792.791"/><path stroke="#A3233B" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" d="M18.167 11.833a5.542 5.542 0 0 0-7.917 0l-6.333 6.334a5.598 5.598 0 1 0 7.916 7.916l.792-.791"/></g></g></svg>
                {translate('Ficha FDMH (UPNA)')}
                </a>
            </li>
            :''
            }
        </ul>
        </>;
    }


    return (
            victimData && victimTabsLink && victimTabsContent ?
                <div className="content" id="victim">
                    <Breadcrumbs key={victimId} currentSite={translate("Buscador")}/>
                    <>
                        <Helmet>
                            <title>{victimNameDisplay(victimData.name, victimData.surname)}</title>
                        </Helmet>
                        <Container>
                            <h2>{victimNameDisplay(victimData.name, victimData.surname)}</h2>
                        {prisionMap?
                        <VictimPrisionMap prision={prisionMovements} victimId={victimId} sitesConvert={sitesConvert} close={() => setPrisionMap(false)}></VictimPrisionMap>
                        :
                        <div className="victim-card">
                            <Row>
                                <Col md={4}>
                                <h3>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="28"><path fill="#000" fillRule="nonzero" d="M11.374 2.625a2.626 2.626 0 1 1-5.252-.001 2.626 2.626 0 0 1 5.252.001ZM8.31 19.25v7c0 .968-.782 1.75-1.75 1.75s-1.75-.782-1.75-1.75v-12.2l-1.564 2.602a1.752 1.752 0 0 1-2.4.596 1.752 1.752 0 0 1-.597-2.4L3.44 9.543a5.255 5.255 0 0 1 4.5-2.548h1.625c1.843 0 3.549.968 4.5 2.548l3.189 5.305c.497.825.23 1.903-.596 2.4a1.752 1.752 0 0 1-2.401-.596l-1.57-2.603V26.25c0 .968-.782 1.75-1.75 1.75s-1.75-.782-1.75-1.75v-7h-.875Z"/></svg>
                                    {translate("Datos personales")}
                                </h3>
                                <ul className="victim-info">
                                    {(victimData.gender)?
                                    <li><span>{translate('Género')}</span> {victimData.gender}</li>
                                    :''}
                                    {(victimData.civil_status)?
                                    <li><span>{translate('Estado civil')}</span> {victimData.ref_vcivil_statusictim_civil_status}</li>
                                    :''}
                                    {(victimData.birthplace)?
                                    <li><span>{translate('Municipio de nacimiento')}</span> {locationsName(victimData.birthplace)}</li>
                                    :''}
                                    {(victimData.location)?
                                    <li><span>{translate('Municipio de residencia')}</span> {locationsName(victimData.location)}</li>
                                    :''}
                                    {(victimData.dead_place)?
                                    <li><span>{translate('Municipio de defunción')}</span> {locationsName(victimData.dead_place)}</li>
                                    :''}

                                </ul>
                                </Col>
                                <Col md={4}>
                                <h3>
                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="25"><path fill="#000" fillRule="nonzero" d="M16.384.198 30.447 8.01a1.563 1.563 0 0 1 .606 2.124 1.563 1.563 0 0 1-2.124.605L15.627 3.352 2.321 10.745a1.563 1.563 0 0 1-2.124-.606 1.563 1.563 0 0 1 .606-2.124L14.87.198a1.557 1.557 0 0 1 1.52 0h-.006Zm-.757 11.524a1.953 1.953 0 1 1 0-3.906 1.953 1.953 0 0 1 0 3.906Zm-8.594 4.687a1.953 1.953 0 1 1 0-3.905 1.953 1.953 0 0 1 0 3.905Zm19.142-1.953a1.953 1.953 0 1 1-3.906.001 1.953 1.953 0 0 1 3.906-.001ZM11.08 23.997l-1.313-2.436v1.88a1.56 1.56 0 0 1-1.563 1.562H5.861A1.56 1.56 0 0 1 4.3 23.441v-1.88l-1.314 2.436A1.173 1.173 0 0 1 .92 22.89l1.85-3.433a4.295 4.295 0 0 1 3.785-2.26h.952c.796 0 1.558.22 2.217.615l1.64-3.042a4.295 4.295 0 0 1 3.785-2.261h.952c1.582 0 3.032.869 3.784 2.26l1.641 3.043a4.302 4.302 0 0 1 2.217-.616h.952c1.582 0 3.032.87 3.784 2.261l1.851 3.433a1.17 1.17 0 0 1-.479 1.587 1.17 1.17 0 0 1-1.587-.479l-1.308-2.436v1.88a1.56 1.56 0 0 1-1.563 1.562H23.05a1.56 1.56 0 0 1-1.562-1.562v-1.88l-1.314 2.436a1.173 1.173 0 0 1-2.065-1.108l1.772-3.296a1.095 1.095 0 0 1-.21-.283l-1.308-2.437v2.661a1.56 1.56 0 0 1-1.563 1.563h-2.344a1.56 1.56 0 0 1-1.562-1.563v-2.66l-1.314 2.436a1.22 1.22 0 0 1-.21.283l1.773 3.296a1.17 1.17 0 0 1-.479 1.587 1.17 1.17 0 0 1-1.587-.479h.005Z"/></svg>
                                    {translate("Datos familiares")}
                                </h3>
                                <ul className="victim-info">
                                    {(victimData.father_name)?
                                    <li><span>{translate('Nombre del padre')}</span> {victimData.father_name}</li>
                                    :''}
                                    {(victimData.mother_name)?
                                    <li><span>{translate('Nombre de la madre')}</span> {victimData.mother_name}</li>
                                    :''}
                                    {(victimData.num_children)?
                                    <li><span>{translate('Número de hijos')}</span> {victimData.num_children}</li>
                                    :''}
                                </ul>
                                </Col>
                                <Col md={4}>
                                <h3>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="23"><path fill="#000" fillRule="nonzero" d="M8.817 2.3h6.9c.21 0 .383.173.383.383V4.6H8.433V2.683c0-.21.173-.383.384-.383Zm-2.684.383V4.6H3.067A3.07 3.07 0 0 0 0 7.667v4.6H24.533v-4.6A3.07 3.07 0 0 0 21.467 4.6H18.4V2.683A2.685 2.685 0 0 0 15.717 0h-6.9a2.685 2.685 0 0 0-2.684 2.683Zm18.4 11.117h-9.2v1.533c0 .848-.685 1.534-1.533 1.534h-3.067A1.532 1.532 0 0 1 9.2 15.333V13.8H0v6.133A3.07 3.07 0 0 0 3.067 23h18.4a3.07 3.07 0 0 0 3.066-3.067V13.8Z"/></svg>
                                    {translate("Datos laborales y políticos")}
                                </h3>
                                <ul className="victim-info">
                                    {(victimData.profession)?
                                    <li><span>{translate('Profesión')}</span> {victimData.profession}</li>
                                    :''}
                                    {(victimData.political_affiliation)?
                                    <li><span>{translate('Filiación política')}</span> {customTrim(victimData.political_affiliation)}</li>
                                    :''}
                                    {(victimData.sindical_affiliation)?
                                    <li><span>{translate('Militancia')}</span> {customTrim(victimData.sindical_affiliation)}</li>
                                    :''}
                                </ul>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={8}>
                                    <Tabs forceRenderTabPanel defaultIndex={victimTab}>
                                        <TabList>
                                            {victimTabsLink}
                                        </TabList>
                                        {victimTabsContent}
                                    </Tabs>
                                </Col>
                                <Col md={4}>
                                    {extras(victimData)}
                                </Col>
                            </Row>
                        </div>
                        }
                        </Container>
                    </>
                </div>
            : <div className="loader1"><div></div><div></div></div>
    )
}
