import React from 'react';
import { getApiCurrentLanguage, getCurrentLanguage, translate } from '../../assets/js/functions';
import parse from 'html-react-parser';
import './assets/scss/styles.scss'
import { Container } from 'react-bootstrap';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';

export default function Expositions() {
  return (
    <div id='expositions'>
        <Breadcrumbs currentSite={translate('Exposiciones')}/>
        <Container>
            <div className="buttons">
                <a href={"https://oroibidea.es/cautividad/"+(getCurrentLanguage() !== 'es' ? getCurrentLanguage() : '')} target="_blank" rel="noreferrer" className="btn button btn-cmn"><span>
                        {parse(translate("La ciudad de los cautivos <br/> Centros de detención en Pamplona, 1936-1945")) }
                    </span>
                    {getCurrentLanguage() == 'en' || getCurrentLanguage() == 'fr'?
                    <><br/><small>{translate('Solo en español')}</small></>
                    :
                    ''}
                </a>
                <a href={"https://navarraobjecioninsumision.navarra.es/"+getCurrentLanguage()} target="_blank" rel="noreferrer" className="btn button btn-cmn"><span>
                        {parse(translate("Memorias de la objeción <br/> y la insumisión en navarra")) }
                    </span>
                    {getCurrentLanguage() == 'en' || getCurrentLanguage() == 'fr'?
                    <><br/><small>{translate('Solo en español')}</small></>
                    :
                    ''}
                </a>
                <a href={"https://exhumacionestempranas.navarra.es/?lang=lg-"+getApiCurrentLanguage()} target="_blank" rel="noreferrer" className="btn button btn-cmn"><span>
                        {parse(translate("Bajo tierra <br/> exhumaciones tempranas en navarra")) }
                    </span>
                    {getCurrentLanguage() == 'en' || getCurrentLanguage() == 'fr'?
                    <><br/><small>{translate('Solo en español')}</small></>
                    :
                    ''}
                </a>
                <a href={"https://oroibidea.es/simbologia/"+(getCurrentLanguage() !== 'es' ? getCurrentLanguage() : '')} target="_blank" rel="noreferrer" className="btn button btn-cmn"><span>
                        {parse(translate("Símbolos de un tiempo")) }
                    </span>
                    {getCurrentLanguage() == 'en' || getCurrentLanguage() == 'fr'?
                    <><br/><small>{translate('Solo en español')}</small></>
                    :
                    ''}
                </a>
            </div>
        </Container>
    </div>
    );
}
